import { PixelModel } from './models';
import { Basket, Book, OtherTaxes } from '../graphqlTypes';
import constant from './constants';

export function constructReferenceObject(path: string, obj): null | unknown {
  if (obj) {
    return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);
  }
  return null;
}

export const SHOW_MODAL_EVENT = 'bp.modal.open';
export const CLOSE_MODAL_EVENT = 'bp.modal.close';
export const BEFORE_UNLOAD = 'beforeunload';
export const month = [
  'ENERO',
  'FEBRERO',
  'MARZO',
  'ABRIL',
  'MAYO',
  'JUNIO',
  'JULIO',
  'AGOSTO',
  'SEPTIEMBRE',
  'OCTUBRE',
  'NOVIEMBRE',
  'DICIEMBRE',
];
export const monthLower = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const correlationPaymentMethods = {
  stripe: 'Tarjeta',
  paypal: 'Paypal',
  contrareembolso: 'contrareembolso',
};

export function tagAvailability(product): string[] {
  let obj = { ok: false, msg: 'No disponible.', status: 'NO DISPONIBLE' };
  if (product.stock_available > 0) {
    obj = {
      ok: true,
      msg: 'En nuestros almacenes. Te lo podemos servir de inmediato',
      status: 'STOCK',
    };
  }
  if (product.unavailable) {
    obj = {
      ok: false,
      msg: 'La Editorial ha vendido toda la edición por lo que este titulo no te lo podemos conseguir mientras no se publique una nueva edición',
      status: 'AGOTADO',
    };
  }
  if (product.not_released) {
    const { scheduled } = product;
    obj = {
      ok: false,
      msg: `Este libro está aun sin publicar. Se estima que su próxima publicación será en el més de ${
        month[scheduled.month]
      } del ${scheduled.year} `,
      status: 'SIN PUBLICAR',
    };
  }
  if (product.reposition_level === 1) {
    obj = {
      ok: false,
      msg: 'Aunque en este momento no tenemos existencias, podemos servírselo en 3 o 4 días.',
      status: '3-4 DÍAS',
    };
  }
  if (product.reposition_level === 2) {
    obj = {
      ok: false,
      msg: 'Aunque en este momento no tenemos existencias, podemos servírselo entre 7 y 10 días.',
      status: '7-10 DÍAS',
    };
  }
  if (product.reposition_level === 3) {
    obj = {
      ok: false,
      msg: 'Este libro hay que importarlo, por lo que, si nos lo encarga, tardaremos de 2 a 3 semanas en enviárselo.',
      status: '2-3 SEMANAS',
    };
  }
  if (product.reposition_level === 4) {
    obj = {
      ok: false,
      msg: 'Este libro hay que importarlo, por lo que, si nos lo encarga, tardaremos de 3 a 6 semanas en enviárselo.',
      status: '3-6 SEMANAS',
    };
  }
  if (product.reposition_level === 5) {
    obj = {
      ok: false,
      msg: 'Este libro hay que importarlo, por lo que, si nos lo encarga, tardaremos de 6 a 12 semanas en enviárselo.',
      status: '3-12 SEMANAS',
    };
  }

  if (product.product_type === 'book' && product.book) {
    if (product.book.lang === 'es') {
      obj = {
        ok: false,
        msg: 'Aunque en este momento no tenemos existencias, podemos servírselo en 3 o 4 días',
        status: '3-4 DÍAS',
      };
    } else {
      obj = {
        ok: false,
        msg: 'Este libro hay que importarlo, por lo que, si nos lo encarga, tardaremos de 2 a 3 semanas en enviárselo',
        status: '2-3 SEMANAS',
      };
    }
  }
  return [obj.status];
}

export function tabByValue(object, ref, between): unknown {
  const value = constructReferenceObject(ref, object);
  if (value) {
    if (between) {
      return value;
    }
    return [value];
  }
  return null;
}

export function tabByObjectAndField(object, ref, prop): unknown[] {
  const result = [];
  const value = constructReferenceObject(ref, object);
  if (value) {
    if (Array.isArray(value)) {
      for (let j = 0; j < value.length; j++) {
        const valor = tabByValue(value[j], prop, true);
        if (valor) {
          result.push(valor);
        }
      }
    } else {
      const valor = tabByValue(value, prop, true);
      if (valor) {
        result.push(valor);
      }
    }
    return result;
  }
  return result;
}

export function getDocumentWidth(document: Document): number {
  return Math.max(
    document.documentElement.clientWidth,
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
  );
}

export function getParameterByName(name, url): string {
  let parsedUrl = url;
  if (!url) {
    parsedUrl = window.location.href;
  }
  const parsedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(parsedUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function canUseDom(): boolean {
  const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
  return canUseDOM;
}

export function getRoot(category: string): string {
  let root = null;
  if (category === 'magazine') {
    root = 'revista/';
  } else if (category === 'book') {
    root = `${constant.allProductCategoriesFull[0]}/`;
  } else if (category === 'ebook') {
    root = `${constant.allProductCategoriesFull[5]}/`;
  } else if (category === 'model') {
    root = `${constant.allProductCategoriesFull[1]}/`;
  } else if (category === 'material') {
    root = `${constant.allProductCategoriesFull[2]}/`;
  } else if (category === 'poster') {
    root = `${constant.allProductCategoriesFull[4]}/`;
  } else {
    root = `${constant.allProductCategoriesFull[0]}/`;
  }
  return `/ficha/${root}`;
}

export function containsElement(area, elem, inner?): boolean {
  let find = false;
  if (!inner) {
    const contains = area.contains(elem);
    if (contains) {
      find = true;
    }
  } else {
    const contains = area.innerText.indexOf(elem.innerText);
    if (contains !== -1) {
      find = true;
    }
  }

  if (!find) {
    const { children } = area;
    if (children.length > 0) {
      for (let i = 0; i < children.length; i += 1) {
        const findItem = containsElement(children[i], elem, true);
        if (findItem) {
          find = true;
        }
      }
    }
  }

  return find;
}

export function getVariants(variants: Book[]): {
  color?: string[];
  finish?: string[];
} {
  const allVariants = {};
  if (variants) {
    for (let i = 0; i < variants.length; i += 1) {
      const prop = variants[i].variant.properties;
      for (let j = 0; j < prop.length; j += 1) {
        const keys = Object.keys(prop[j]);
        for (let p = 0; p < keys.length; p += 1) {
          if (
            prop[j][keys[p]] &&
            (!allVariants[keys[p]] ||
              (allVariants[keys[p]] && allVariants[keys[p]].indexOf(prop[j][keys[p]]) === -1))
          ) {
            if (allVariants[keys[p]]) {
              allVariants[keys[p]].push(prop[j][keys[p]]);
            } else {
              allVariants[keys[p]] = [prop[j][keys[p]]];
            }
          }
        }
      }
    }
  }
  return allVariants;
}
export function parseCategory(category: string): string {
  let res = '';
  switch (category) {
    case 'book':
      res = 'libros';
      break;
    case 'ebook':
      res = 'ebooks';
      break;
    case 'material':
      res = 'materiales';
      break;
    case 'model':
      res = 'modelos';
      break;
    case 'poster':
      res = 'láminas';
      break;
    default:
      break;
  }
  return res;
}

export function roundToTwo(num: number): number {
  return +`${Math.round(Number(`${num}e+2`))}e-2`;
}

export function capitalizeFirstLetter(data: string): string {
  return data[0].toUpperCase() + data.slice(1).toLowerCase();
}

export const correlation = {
  book: 'libros',
  model: 'modelos-anatomicos-educativos',
  material: 'material-y-suministros-medicos',
  poster: 'poster',
  ebook: 'ebooks',
};
export const correlationNames = {
  book: 'libros',
  model: 'modelos',
  material: 'material',
  poster: 'poster',
  ebook: 'ebooks',
};
export const correlationInverse = {
  libros: 'book',
  modelos: 'model',
  'modelos-anatomicos-educativos': 'model',
  material: 'material',
  'material-y-suministros-medicos': 'material',
  poster: 'poster',
  ebook: 'ebooks',
};

export const newCorrelationNames = {
  libro: 'libros',
  modelo: 'modelos-anatomicos-educativos',
  material: 'material-y-suministros-medicos',
  poster: 'poster',
  ebook: 'ebooks',
};

export function buildOtherTaxesAmount(otherTaxes: OtherTaxes[]): number {
  if (otherTaxes && otherTaxes.length > 0) {
    let total = 0;
    for (let i = 0; i < otherTaxes.length; i += 1) {
      if (otherTaxes[i].amount && otherTaxes[i].description) {
        total += otherTaxes[i].amount;
      }
    }
    return total;
  }
  return 0;
}

export const getCategoryFromSection = (section: string, nameCategoryFull = true): string => {
  const { allProductCategoriesFull, allProductCategoriesP } = constant;

  const nameProductCategory = nameCategoryFull ? allProductCategoriesFull : allProductCategoriesP;

  switch (section) {
    case nameProductCategory[0]:
      return 'book';
    case nameProductCategory[1]:
      return 'model';
    case nameProductCategory[2]:
      return 'material';
    case nameProductCategory[3]:
      return 'magazines';
    case nameProductCategory[4]:
      return 'poster';
    case nameProductCategory[5]:
      return 'ebook';
    default:
      return null;
  }
};

/**
 * Function receive a params and return a friendly title for SEO
 * @param section 'libros', 'ebooks', 'modelos', 'material', 'poster'
 * @returns
 */
export const getSectionNameFromRoute = (section: string): string => {
  let title = 'Libros';
  switch (section) {
    case 'libros':
      title = 'Libros de medicina y otras ciencias de la salud';
      break;
    case 'ebooks':
      title = 'eBooks de medicina y otras ciencias de la salud';
      break;
    case 'modelos':
    case 'modelos-anatomicos-educativos':
      title = 'Simuladores Médicos y Modelos Anatómicos';
      break;
    case 'material':
    case 'material-y-suministros-medicos':
      title = 'Material Médico y Mobiliario Clínico';
      break;
    case 'poster':
      title = 'Láminas';
      break;
    default:
      title = 'Libros';
      break;
  }
  return title;
};

/**
 * Function receive a order and optional area for parsed filters
 * @param order
 * @returns
 */
export const getParsedSortAndOrderFilters = (
  order: string,
  areaValue?: string,
): { sortType: string; orderType: number } => {
  let sortType = 'title';
  let orderType = 1;

  switch (order) {
    case 'alfabeta':
      sortType = 'titleFriendly';
      orderType = 1;
      break;

    case 'alfabetaBack':
      sortType = 'titleFriendly';
      orderType = -1;
      break;

    case 'euro':
      sortType = 'prices.sale';
      orderType = 1;
      break;

    case 'euroHigh':
      sortType = 'prices.sale';
      orderType = -1;
      break;

    case 'calendar':
      sortType = 'book.edition.year';
      orderType = areaValue ? 1 : -1;
      break;

    case 'book.edition.year':
      sortType = 'book.edition.year';
      orderType = areaValue ? 1 : -1;
      break;

    case 'visited':
      sortType = 'clicks';
      orderType = -1;
      break;

    case 'created_at':
      sortType = 'created_at';
      orderType = -1;
      break;

    case 'clicks':
      sortType = 'clicks';
      orderType = -1;
      break;

    case '':
      sortType = 'clicks';
      orderType = -1;
      break;

    case 'bestSellers':
      sortType = 'sales';
      orderType = -1;
      break;

    default:
      sortType = 'title';
      orderType = 1;
      break;
  }

  return { orderType, sortType };
};

/**
 * Function verify if is client and can use localStorage or window
 * @returns
 */
export const isClient = (): boolean => typeof window !== 'undefined';

export const getUrlPixel = (basket: Basket[]): string => {
  const urlPixel = `https://gethatch.com/iceleads_rest/merch/${constant.paypal.merchId}/direct`;
  const dataPixel: PixelModel[] = basket.map((bask) => {
    return {
      cur: 'EUR',
      ean: bask.ean,
      height: String(bask.book.dimensions.height) || '0',
      width: String(bask.book.dimensions.width) || '0',
      mpn: String(bask.book.id),
      pr: String(bask.book.priceWithDiscount),
      prod_name: bask.book.titleFriendly,
      qty: String(bask.units),
      proprietary_id: String(bask.book.id),
      vendor_name: bask.book.product_type,
    };
  });

  const pixelParams = Object.entries(dataPixel)
    .map(([key, value]) => `${key}=${value}`)
    .join(';');

  return `${urlPixel}${pixelParams}`;
};

export const RESIZE = 'resize';
export const ORIENTATION_CHANGE = 'orientationchange';
export const HISTORY_ROUTES_KEY = 'axon.history';
export const HISTORY_BACK_KEY = 'bp.back';

export const getInfoDiscount = (
  product: Book,
  available?: 'soon' | string,
): {
  isPriceClearing: boolean;
  isPrepublication: boolean;
  isFlashOffer: boolean;
  isSoom: boolean;
} => {
  return {
    isPriceClearing: !!product.prices?.clearing,
    isPrepublication:
      product?.prepublication?.end &&
      product?.prepublication?.launching &&
      Date.now() < new Date(product.prepublication.end).getTime() &&
      Date.now() >= new Date(product.prepublication.start).getTime(),
    isFlashOffer:
      product?.flashOffer?.launching &&
      product?.flashOffer?.end &&
      new Date() < new Date(product.flashOffer.end) &&
      (!product?.flashOffer?.start || new Date() >= new Date(product.flashOffer.start)),
    isSoom: available === 'soon',
  };
};
