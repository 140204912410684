import { useMemo } from 'react';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
} from '@apollo/client';
import cookies from 'react-cookies';
import getConfig from 'next/config';

import { setContext } from '@apollo/client/link/context';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { positionValues } from 'react-custom-scrollbars';
import { scrollbarValuesVar } from './reactiveVars/scrollbarValues';
import userReactiveVar from '../hooks/useReactiveVariableUser';
import { User } from '../graphqlTypes';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getApiUrl = (): string => {
  const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
  const url =
    publicRuntimeConfig?.NEXT_PUBLIC_API_URL ||
    serverRuntimeConfig?.NEXT_PUBLIC_API_URL ||
    process.env.NEXT_PUBLIC_API_URL;

  if (!url) {
    return 'https://api.axon.es/';
  }

  return url;
};

function createApolloClient(cookieToken?: string): ApolloClient<NormalizedCacheObject> {
  const authLink = setContext((_, { headers }) => {
    const token = cookieToken || cookies.load('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const url = getApiUrl();

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(
      createHttpLink({
        uri: `${url}graphql`,
        credentials: 'same-origin',
      }),
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            user: {
              read(): User {
                return userReactiveVar();
              },
            },
            scrollbarValues: {
              read(): positionValues {
                return scrollbarValuesVar();
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(
  initialState = {},
  token?: string,
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(token);

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSR we generate a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: OperationVariables,
): Record<string, unknown> {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: OperationVariables): ApolloClient<NormalizedCacheObject> {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
